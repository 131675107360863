import React from "react"
import { Helmet } from "react-helmet"
import { Router } from "@reach/router"
import { SkoleniDetail } from "../components/goods/SkoleniDetail"
import { Skoleni } from "../components/goods/Skoleni"
import SkoleniList from "../components/goods/SkoleniList"

const ZivePage = ({ location }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Živě online</title>
      </Helmet>

      <SkoleniList
        paging={16}
        page={1}
        tags={[]}
        typ={"ziva"}
        location={location}
      />
    </>
  )
}

export default ZivePage
